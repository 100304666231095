.login-box {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 20%;

    .login-body {
        padding-top: 5%;

        h1 {
            font-size: 210px;
            font-weight: 900;
            line-height: 210px;
        }
    }

    .footer {
        width: 100%;
        left: 0px;
        right: 0px;
    }
}