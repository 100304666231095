body {
    margin: 0;
    background: #F6F7FB;
}

.issp-root {
    display: flex;
    width: 100%;

    &.hide {
        .issp-side {
            width: 3.75rem;
            position: fixed;
            margin: 0 1rem;

            .issp-brand {
                .issp-brand-name {
                    display: none;
                }
            }

            .issp-list {
                flex-flow: row wrap;

                .issp-nav-link {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: clip;

                    .issp-icon {
                        margin: 0;
                    }

                    .issp-name {
                        display: none;
                    }

                    &.active {
                        width: 100%;
                        color: #A9162A;
                        border-right: none;
                    }
                }
            }

            &:hover {
                width: 240px;

                .issp-brand {
                    .issp-brand-name {
                        display: block;
                    }
                }

                .issp-list {
                    .issp-nav-link {
                        .issp-icon {
                            margin-right: .75rem;
                        }

                        .issp-name {
                            display: block;
                        }

                        &.active {
                            width: 100%;
                            color: #A9162A;
                            border-right: 4px solid #A9162A;
                        }
                    }
                }
            }
        }

        .issp-main {
            position: absolute;
            right: 0;
            left: 76px;
        }
    }
}

.issp-side {
    width: 240px;
    height: calc(100vh - 2rem);
    margin: 1rem 0 1rem 1rem;
    z-index: 25;
    position: sticky;
    top: 1rem;

    flex: none;
    border-radius: .5rem;

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

    .issp-brand {
        height: 60px;
        max-width: 100%;
        padding: 1rem;

        border-radius: .5rem .5rem 0 0;
        display: flex;
        align-items: center;

        // background: #2F323E;

        .issp-brand-image {
            margin-left: 1px;
            width: 100%;
        }

        .issp-brand-name {
            color: white;
            margin-left: .5rem;
            font-weight: 600;
            font-size: 18px;
        }

        .issp-mobile-toggle {
            display: none;
        }
    }

    .issp-list {
        width: 100%;
        height: calc(100% - 60px);
        padding-top: 1rem;

        border-radius: 0 0 .5rem .5rem;

        background-color: white;
    }
}

.issp-nav-link {
    width: 100%;
    padding: 6px 1.125rem;
    margin-bottom: 1rem;
    
    display: flex;
    align-items: center;
    text-decoration: none;

    color: rgb(0, 0, 0);
    opacity: .25;

    .issp-icon {
        width: 1.5rem;
        height: 1.5rem;

        margin-right: .75rem;
    }

    .issp-name {
        font-weight: 600;
        font-size: 14px;
        margin-top: 2px;
        line-height: 14px;
    }

    &:hover {
        color: #A9162A;
        text-decoration: none;
        
        opacity: 1;
    }

    &.active {
        width: 100%;
        color: #A9162A;
        opacity: 1;
        border-right: 4px solid #A9162A;
    }
}

.issp-main {
    flex: 1;
}

.issp-bg-nav {
    position: sticky;
    top: 0px;
    padding: 1rem;
    background: #F6F7FB;
    z-index: 23;
}

.issp-nav {
    display: flex;
    max-width: 100%;
    height: 60px;
    padding: 10px;
    justify-content: center;
    position: sticky;
    top: 1rem;

    border-radius: .5rem;

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    background: #A9162A;

    .issp-sidebar-toggle {
        height: 40px;
        width: 40px;
        padding: .5rem;
        
        border-radius: .5rem;
        cursor: pointer;

        color: white;
        background: rgba(0, 0, 0, 0.15);

        &:hover {
            background: rgba(0, 0, 0, 0.25);
        }
    }

    .issp-alert {
        height: 1.5rem;
        width: 1.5rem;
        margin: .5rem .75rem .5rem auto;

        // cursor: not-allowed;

        color: white;
    }

    .issp-avatar {
        height: 2.25rem;
        width: 2.25rem;
        margin: 3px 0;

        border: 2px solid white;
        background-color: white;
        background-clip: border-box;
        border-radius: 50%;
        cursor: pointer;

        object-fit: cover;
    }
}

.issp-content {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btn-table {
    width: 2rem;
    height: 2rem;
    padding: 6px;

    background-color: rgba(0, 0, 0, .05);
}

.issp-table th, .issp-table td {
    vertical-align: middle;
}

.footer-success {
    cursor: pointer;
}

.footer-success:hover {
    background: #00ac69!important;
    color: white!important;
}

.footer-warning {
    cursor: pointer;
}

.footer-warning:hover {
    background: #f76400!important;
    color: white!important;
}

.badge-public {
    padding: 7px 6px 6px 6px;
    margin-top: -2px;
    margin-bottom: -2px;
    display: flex;
    width: fit-content;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    border-radius: 4px;
    background-color: rgba(#e81500, .1);
    color: #e81500;

    &.public {
        background-color: rgba(#0061f2, .1);
        color: #0061f2;
    }
}

.app-id-text {
    cursor: pointer;

    .copy-icon {
        display: none;
    }

    &:hover {
        text-decoration: underline;

        .copy-icon {
            display: initial;
        }
    }
}

.copy-status {
    position: absolute;
    top: -16px;
    left: 23%;
}

.btn-filter {
    background-color: white;
    border-color: rgba(#0061f2, .15);
    color: #0061f2;

    font-weight: 500;

    &:hover {
        background-color: rgb(250, 250, 250);
        border-color: rgba(#0061f2, .15);
        color: #0061f2;
    }
}

@media only screen and (min-width: 992px) {
    .card-equal {
        position: absolute;
        width: calc(100% - 1.5rem);
    }
} 

@media only screen and (max-width: 992px) {
    .issp-root {
        .issp-main {
            position: absolute;
            right: 0;
            left: 76px;
        }
        
        .issp-side {
            width: 260px;
            position: fixed;
            top: unset;

            .issp-brand {
                .issp-brand-name {
                    display: block;
                }

                .issp-mobile-toggle {
                    height: 40px;
                    width: 40px;
                    padding: .5rem;
                    right: 1rem;
                    
                    border-radius: .5rem;
                    cursor: pointer;

                    background: rgba(0, 0, 0, 0.15);
                    color: white;
                    
                    position: absolute;
                    display: block;
                }
            }

            .issp-list {
                .issp-nav-link {
                    .issp-icon {
                        margin-right: .75rem;
                    }

                    .issp-name {
                        display: block;
                    }

                    &.active {
                        width: 100%;
                        color: #A9162A;
                        border-right: 4px solid #A9162A;
                    }
                }
            }
        }

        &.hide {
            .issp-side {
                display: none;
            }

            .issp-main {
                left: 0;
            }
        }
    }

    .card-equal {
        max-height: 20rem!important;
    }
}