label.text-dark {
    font-weight: 500!important;
}

.btn-issp {
    font-size: .875rem;

    .icon-btn {
        margin-top: -2px;
        margin-right: .5rem;
    }

    span {
        margin-bottom: -2px;
    }
}

.rbt-input-main {
    cursor: pointer!important;
}

.nav-link.issp {
    cursor: pointer;
}