.media-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    cursor: pointer;

    p {
        font-size: 1rem;
        margin-bottom: 0;
    }

    em {
        font-size: .8rem;
    }
}

.media-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    border-width: 2px;
    border-radius: .35rem;
    border-color: #cacaca;
    border-style: dashed;
    background-color: #fafafa;
    color: #828282;
    outline: none;
    transition: border .24s ease-in-out;

    &:focus {
        border-color: #898a8c;
    }

    &.disabled {
        opacity: 0.6;
    }
}